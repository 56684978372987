/* You can add global styles to this file, and also import other style files */

.mainTheme {
  //color:bisque;
  //background-color: rebeccapurple;
  color: black;
  background-color: lightsteelblue;
  font-size: 15px;
  //font-family: Verdana,sans-serif;
  //font-family: Comic Sans MS, cursive;
  //font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

h1 {
  color: floralwhite;
  background-color: navy;
  position: fixed;
  height:auto;
  width: 100%;
  
  margin-top:10px;

  z-index:3;

}

h2{

  color:dimgray;
  
}

.spaceH1 {
  padding-top: 80px;
}



#wrapper {
  width: 100vw;
  min-width: 300px;
  max-width: 650px;
  margin-left: -5px;
  overflow-x: hidden;
}


iframe {
  //standard
  width: 100%;
  min-width: 300px;
  max-width: 1000px;
  height:auto;

  background-color: beige;
  color: black;
  border-radius: 3px;
  border-color: black;
  border-style: solid;
  //varaible
}

.img_VBA{
  width: 100%;
  min-width: 300px;
  max-width: 1000px;
  height: auto;
}


object {
  width: 100%;
  min-width: 300px;
  max-width: 1000px;
  height: auto;
}

a:visited {
    color:gray;
}



